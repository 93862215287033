import React, {useState} from 'react';

import {Form} from 'semantic-ui-react';
import ReactDOM from 'react-dom';
import {v4 as uuidv4} from 'uuid';

const ReactComponent = ({className, name, value}) => {
  const [inputName, setInputName] = useState(name);
  const [inputValue, setInputValue] = useState(value);

  return (
    <div className={className}>
      <Form className="mb-15">
        <Form.Input
          fluid
          label="Field Name"
          placeholder="Title of this information"
          value={inputName}
          onChange={(e, {value}) => {
            e.preventDefault();
            setInputName(value);
          }}
        />
        <Form.TextArea
          label="Field Value"
          placeholder="Body of this information"
          value={inputValue}
          onChange={(e, {value}) => {
            e.preventDefault();
            setInputValue(value);
          }}
        />
      </Form>
    </div>
  );
};

export default class CustomParagraph {
  constructor({data, api, config}) {
    // provided by EditorJS
    this.api = api;
    this.config = config;
    this.data = data;

    this.container = undefined;

    this._CSS = {
      block: this.api.styles.block,
      react: 'react-component',
    };

    this.blockId = uuidv4();
  }

  static get toolbox() {
    return {
      title: 'Plain Info',
      icon:
        '<svg width="16" height="17" viewBox="0 0 320 294" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M160.5 97c12.426 0 22.5 10.074 22.5 22.5v28c0 12.426-10.074 22.5-22.5 22.5S138 159.926 138 147.5v-28c0-12.426 10.074-22.5 22.5-22.5zm0 83c14.636 0 26.5 11.864 26.5 26.5S175.136 233 160.5 233 134 221.136 134 206.5s11.864-26.5 26.5-26.5zm-.02-135c-6.102 0-14.05 8.427-23.842 25.28l-74.73 127.605c-12.713 21.444-17.806 35.025-15.28 40.742 2.527 5.717 8.519 9.175 17.974 10.373h197.255c5.932-1.214 10.051-4.671 12.357-10.373 2.307-5.702-1.812-16.903-12.357-33.603L184.555 70.281C174.608 53.427 166.583 45 160.48 45zm154.61 165.418c2.216 6.027 3.735 11.967 4.393 18.103.963 8.977.067 18.035-3.552 26.98-7.933 19.612-24.283 33.336-45.054 37.586l-4.464.913H61.763l-2.817-.357c-10.267-1.3-19.764-4.163-28.422-9.16-11.051-6.377-19.82-15.823-25.055-27.664-4.432-10.03-5.235-19.952-3.914-29.887.821-6.175 2.486-12.239 4.864-18.58 3.616-9.64 9.159-20.55 16.718-33.309L97.77 47.603c6.469-11.125 12.743-20.061 19.436-27.158 4.62-4.899 9.562-9.07 15.206-12.456C140.712 3.01 150.091 0 160.481 0c10.358 0 19.703 2.99 27.989 7.933 5.625 3.356 10.563 7.492 15.193 12.354 6.735 7.072 13.08 15.997 19.645 27.12l.142.24 76.986 134.194c6.553 10.46 11.425 19.799 14.654 28.577z"></path></svg>',
    };
  }

  render() {
    if (!this.container) {
      this.container = document.createElement('div');
      this.container.classList.add(this._CSS.block);

      ReactDOM.render(
        <ReactComponent
          {...this.data}
          className={this._CSS.react}
          blockId={this.blockId}
        />,
        this.container,
      );
    }
    return this.container;
  }

  rendered() {}

  moved() {}

  removed() {
    ReactDOM.unmountComponentAtNode(this.container);
  }

  save(element) {
    return {
      name:
        element.children[0].children[0].children[0].children[1].children[0]
          .value,
      value: element.children[0].children[0].children[1].children[1].value,
    };
  }
}
